
import { Component, Vue } from "vue-property-decorator";
import { mapMutations, mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

@Component({
    methods: {
        ...mapMutations({
            setDrawer: types.MUTATE_APP_DRAWER,
            setLogo: types.MUTATE_LOGO
        }),
        ...mapActions({
            authLogout: types.AUTH_LOGOUT
        })
    },
    computed: {
        ...mapGetters({
            drawer: types.APP_DRAWER,
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            userName: types.AUTH_USERNAME,
            userIdentifier: types.USER_IDENTIFIER,
            logo: types.LOGO,
            services: types.SERVICIOS
        })
    }
})
export default class AppBar extends Vue {
    /**
     * Methods
     */

    setProgressBar!: (state: boolean) => void;
    setDrawer!: (state: boolean) => void;
    setLogo!: (state: string) => void;
    authLogout!: () => void;
    collapse = 0;
    company = 1;
    userIdentifier?: number;
    logo?: string;
    services?: string[];
    Nempresa?: number;
    isAdmin = true;
    itemsManagement = [
        {
            title: this.$t("appBar.prices"),
            icon: "mdi-account-cash",
            to: "/prices",
            roles: ["client", "admin"]
        }
    ];

    itemsAnalitic = [
        {
            title: this.$t("appBar.resumenFast"),
            icon: "mdi-file-eye",
            to: "/resumen",
            roles: ["client", "admin"],
            serv: "4"
        },
        {
            title: this.$t("appBar.advanced"),
            icon: "mdi-abacus",
            to: "/advanced",
            roles: ["client", "admin"],
            serv: "5"
        },
        {
            title: this.$t("appBar.energyDev"),
            icon: "mdi-battery-charging-60",
            to: "/energy",
            roles: ["client", "admin"],
            serv: "6"
        }
    ];

    itemsGestor = [
        {
            title: "Contador de Energia",
            icon: "mdi-pier-crane",
            to: "/contador",
            roles: ["client", "admin"],
            serv: "10"
        },
        {
            title: this.$t("appBar.summary"),
            icon: "mdi-ballot",
            to: "/summary",
            roles: ["client", "admin"],
            serv: "7"
        },
        {
            title: this.$t("appBar.precios"),
            icon: "mdi-hand-coin-outline",
            to: "/costes",
            roles: ["client", "admin"],
            serv: "8"
        },
        {
            title: this.$t("appBar.saving"),
            icon: "mdi-cash-lock",
            to: "/saving",
            roles: ["client", "admin"],
            serv: "9"
        }
    ];

    itemsDevelop = [
        {
            title: "Consumo por Períodos",
            icon: "mdi-transmission-tower-export",
            to: "/consdia",
            roles: ["admin"]
        },
        {
            title: "Ingestador CSV",
            icon: "mdi-database-import",
            to: "/ingestor",
            roles: ["admin"]
        },
        {
            title: "Euskabea ISO",
            icon: "mdi-wind-turbine-check",
            to: "/euskabea",
            roles: ["admin"]
        },
        {
            title: "Mapa de Instalaciones Satiins",
            icon: "mdi-compass-rose",
            to: "/map",
            roles: ["admin"]
        },
        {
            title: "Variables Calculadas",
            icon: "mdi-abacus",
            to: "/algebra",
            roles: ["admin"]
        },
        {
            title: "Datadis",
            icon: "mdi-home-battery-outline",
            to: "/datadis",
            roles: ["admin"]
        },
        {
            title: "Logs",
            icon: "mdi-clipboard-text-clock-outline",
            to: "/logs",
            roles: ["admin"]
        },
        {
            title: "Instalacion",
            icon: "mdi-clippy",
            to: "/instalation",
            roles: ["admin"]
        },
        {
            title: "Prediccion",
            icon: "mdi-crystal-ball",
            to: "/prediction",
            roles: ["admin"]
        },
        {
            title: "I-frame",
            icon: "mdi-application-braces",
            to: "/external",
            roles: ["admin"]
        },
        {
            title: "Forecasting",
            icon: "mdi-crystal-ball",
            to: "/forecast",
            roles: ["admin"]
        }
    ];

    itemsInstallation = [
        {
            title: this.$t("appBar.installation"),
            icon: "mdi-human-male-board-poll",
            to: "/installation",
            roles: ["client", "admin"],
            serv: "3"
        }
    ];
    /**
     * Computed
     */

    async mounted() {
        if (Vue.prototype.$user.get().role == "client") {
            this.isAdmin = false;
            try {
                const userClientResponse = await this.$api.SQLuserClient<any>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0].split(" - ")[0];
                    switch (Number(this.company)) {
                        case 6:
                            this.setLogo("img/logo/logo_satiins.jpg");
                            break;
                        case 9:
                            this.setLogo("img/logo/PasaiaPort.png");
                            break;
                        case 10:
                            this.setLogo("img/logo/prodesa.png");
                            break;
                        default:
                            this.setLogo("img/logo/euskabea.jpg");
                            break;
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    "Nothing";
                }
            }
        } else {
            this.isAdmin = true;
            this.setLogo("img/logo/euskabea.jpg");
        }
    }

    get drawer() {
        return !this.$store.state.app.drawer;
    }

    set drawer(val) {
        this.setDrawer(val);
    }

    get sectionsM() {
        return this.itemsManagement.filter(item =>
            item.roles.includes(Vue.prototype.$user.get().role)
        );
    }

    get sectionsA() {
        return this.itemsAnalitic;
    }

    get sectionsG() {
        if (this.isAdmin) {
            return this.itemsGestor.filter(item =>
                item.roles.includes(Vue.prototype.$user.get().role)
            );
        } else {
            return this.itemsGestor.filter(
                item =>
                    item.roles.includes(Vue.prototype.$user.get().role) &&
                    this.services?.includes(item.serv)
            );
        }
    }

    get sectionsD() {
        return this.itemsDevelop.filter(item =>
            item.roles.includes(Vue.prototype.$user.get().role)
        );
    }

    get sectionsI() {
        // return [];
        return this.itemsInstallation.filter(item =>
            item.roles.includes(Vue.prototype.$user.get().role)
        );
    }

    get haveI() {
        if (this.isAdmin) {
            return true;
        } else {
            return this.services?.includes("3");
        }
    }

    colapseAll() {
        this.$root.$emit("reloadAppBar");
    }

    logout() {
        // if (this.Nempresa == 1) {
        //     this.$router.push("/euskabea");
        // } else if (this.Nempresa == 9) {
        //     this.$router.push("/pasaiaport");
        // } else if (this.Nempresa == 10) {
        //     this.$router.push("/prodesa");
        // } else {
        //     this.$router.push("/signin");
        // }
        this.$router.push("/signin");
        localStorage.clear();
        this.authLogout();
    }

    createUser() {
        this.$router.push("/user-management");
    }

    getRouteName() {
        return this.$route.name;
    }
}
